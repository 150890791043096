<template>
  <div>
    <v-dialog v-model="loading" max-width="500" persistent>
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear
              active
              indeterminate
              query
              rounded
              stream
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--    get number dialog-->
    <v-dialog v-model="needToReceivedCell" width="500" persistent>
      <v-form ref="editPhone" lazy-validation>
        <v-card class="ga_signup ga_signup_new_preorder">
          <v-card-title>
            <p class="text-center">
              لطفا شماره تماس خود را وارد کنید
            </p>
          </v-card-title>
          <v-card-text>
            <v-text-field
                class="mt-2"
                v-model="tell"
                outlined
                prepend-icon="phone"
                type="tel"
                name="tel"
                placeholder="example : +98 123 123 1234"
                :rules="phoneRules"
                style="direction: ltr"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="editProfile" :loading="loadingTell">ثبت</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>


    <v-snackbar
        :id="snackbarId"
        v-model="snackbar.visibility"
        :color="snackbar.color"
        :bottom="snackbar.position === 'bottom'"
        :timeout="snackbar.timeout"
        :top="snackbar.position === 'top'"
    >
      {{snackbar.message}}
      <v-btn
          color="primary--text"
          @click="snackbar.visibility = false"
          fab
          x-small
      >
        <v-icon x-small color="red">fas fa-times</v-icon>
      </v-btn>
    </v-snackbar>

    <!--    get number dialog-->
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import LS from '../LocalStorateAPI';
import {AsYouType, parsePhoneNumberFromString} from "libphonenumber-js";
import test from "../../test";

export default {
  created() {
    if (this.$route.query.token != undefined) {
      this.$store.commit('setToken', {token: 'Bearer ' + this.$route.query.token});
      this.$store.dispatch('getUserInfo').then((resp) => {

        window.webengage.user.login(this.$store.getters.getUserInfo.id);
        if (this.$route.query.registered != undefined) {
          this.sendWebEngageRegisterEvent();
        }
        this.$store.commit('setLoggedIn');
        EventBus.$emit('userLoggedIn');
        let surveyData = LS.get('surveyData');
        this.tell = resp.profile.cel
        console.log(resp.profile.cel != null ? 'not null' :'null')
        if ( resp.profile.cel != null) {
          if (surveyData != null && surveyData != undefined) {
            this.$store.dispatch('postSurveyAnswers', surveyData).then(() => {
              this.$router.push({
                name: 'xpreorder', params: {
                  orderId: 68
                }
              });
            });
          } else {
            this.$router.push({
              name: 'xpreorder', params: {
                orderId: 68
              }
            });
          }
        } else {
          this.needToReceivedCell = true
        }
      })
    }
    return;
  },
  methods: {
    sendWebEngageRegisterEvent() {
      window.webengage.track('registered_on', {
        date: this.registered_on
      });
    },
    showSnackbar(color = 'success', timeout = 5000, message = 'عملیات با موفقیت انجام شد.',snackbarId=null){
      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

      if(snackbarId!=null) {

        this.$store.commit('SET_SNACKBAR_ID',snackbarId)

      }
    },
    editProfile() {
      if(this.$refs.editPhone.validate()){
        this.loadingTell = true
        this.$store.dispatch('saveUserInfo', {
          mobile: this.tell
        }).then(resp => {
          EventBus.$emit('notify', 'green', 3000, this.$t('$vuetify.saveSuccessfullyMsg'), this.GALogin);
          this.$router.push({
            name: 'xpreorder', params: {
              orderId: 68
            }
          });
        }).finally(()=>{
          this.loadingTell = false
        }).catch(()=>{
          EventBus.$emit('notify', 'green', 3000, 'فرایند با مشکل مواجه شد. دوباره تلاش کنید', this.GALogin);
        })
      }
    }
  },
  data() {
    return {
      registered_on: new Date(),
      loading: true,
      needToReceivedCell: false,
      tell: null,
      loadingTell:false,
      temp:'',
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
    }
  },
  computed: {
    phoneRules() {
      return [
        input => {
          if (input != null){
            let phone = input.replaceAll(' ', '');
            const phoneNumber = parsePhoneNumberFromString(phone);
            if (phoneNumber) {
              if (!phoneNumber.isValid()) {
                return this.$t('$vuetify.Notify.signupComponent.phoneNumberError');
              } else {
                return true;
              }
            } else {
              return this.$t('$vuetify.zeroPayment.phoneRequired');
            }
          }
          return false
        }
      ]
    },
  },
  watch: {
    tell() {
      if (this.tell.startsWith('00')) {
        this.tell = this.tell.substr(2);
        this.tell = '+' + this.tell;
      }

      if (this.tell.startsWith('098')) {
        this.tell = this.tell.substr(1);
        this.tell = '+' + this.tell;
      }

      if (this.tell.startsWith('09')) {
        this.tell = this.tell.substr(1);
        this.tell = '+98' + this.tell;
      }

      this.tell = new AsYouType().input(this.tell);

    },
  },
  mounted() {
    EventBus.$on('notify', this.showSnackbar);
    this.$store.commit('setMinHeight');
  },
}
</script>


<style scoped>

</style>